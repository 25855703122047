import React from 'react';
import './PortfolioSection.css';
import image2 from '../assets/images/image2.jpg'
import image15 from '../assets/images/image15.jpg'
import image12 from '../assets/images/image12.jpg'
import image18 from '../assets/images/image18.jpg'
import image13 from '../assets/images/image13.jpg'
import image7 from '../assets/images/image7.jpg'
import image16 from '../assets/images/image16.jpg'
import image20 from '../assets/images/image20.jpg'
import image19 from '../assets/images/image19.jpg'

const PortfolioSection = () => {
  return (
    <section className="portfolio-section">
      <div className="portfolio-container">
        <div className="portfolio-column">
          <div className="portfolio-widget-wrap">
            <div className="portfolio-widget">
              <div className="portfolio-widget-container">
                {/* <div className="portfolio-filter">
                  <button className="portfolio-btn is-checked" data-filter="*">All</button>
                  <button className="portfolio-btn" data-filter=".cat-28" title="Aluminium Doors & Windows">Aluminium Doors & Windows</button>
                  <button className="portfolio-btn" data-filter=".cat-30" title="Glass façade">Glass façade</button>
                  <button className="portfolio-btn" data-filter=".cat-26" title="uPVC Windows">uPVC Windows</button>
                  <button className="portfolio-btn" data-filter=".cat-31" title="Upvc, Aluminium & GI Louvers">Upvc, Aluminium & GI Louvers</button>
                  <button className="portfolio-btn" data-filter=".cat-32" title="Vox">Vox</button>
                </div> */}
                <div className="portfolio-grid">
                <PortfolioItem
                      category="cat-28"
                      imgSrc={image2}
                      link={image2}
                      title="uPVC Openable Windows and Doors "
                      excerpt="uPVC Openable Windows and Doors are ideal for windows with or without open shutters, external"
                      position={{ left: '389.99px', top: '0px' }}
                    />
                    <PortfolioItem
                      category="cat-28"
                      imgSrc={image15}
                      link={image15}
                      title="glass Railing"
                      excerpt="Our elegant glass railing systems offer a perfect blend of safety and modern aesthetics, providing unobstructed views while enhancing the architectural beauty of any space."
                      position={{ left: '779.98px', top: '0px' }}
                    />
                    <PortfolioItem
                      category="cat-28"
                      imgSrc={image12}
                      link={image12}
                      title="Slim Profile"
                      excerpt="Create a sleek, modern aesthetic with our slim profile designs, offering unobstructed views and a minimalist elegance for any space"
                      position={{ left: '0px', top: '524.885px' }}
                    />
                    <PortfolioItem
                      category="cat-28"
                      imgSrc={image18}
                      link={image18}
                      title="Stairs Steel Railing "
                      excerpt="Enhance your space with our sleek and durable steel stair railings, designed for both safety and modern aesthetic appeal."
                      position={{ left: '389.99px', top: '0px' }}
                    />
                    <PortfolioItem
                      category="cat-28"
                      imgSrc={image13}
                      link={image13}
                      title="Black uPVC Three Track Sliding Window"
                      excerpt="Experience the perfect blend of style and functionality with our black uPVC three-track sliding window, offering smooth operation and maximum ventilation"
                      position={{ left: '779.98px', top: '0px' }}
                    />
                    <PortfolioItem
                      category="cat-28"
                      imgSrc={image7}
                      link={image7}
                      title="White uPVC Three Track Sliding Window"
                      excerpt="Upgrade your home with our white uPVC three-track sliding window, providing effortless sliding action, enhanced ventilation, and a clean, modern look. "
                      position={{ left: '0px', top: '524.885px' }}
                    />
                    <PortfolioItem
                      category="cat-28"
                      imgSrc={image16}
                      link={image16}
                      title="Aluminium Doors and Widows "
                      excerpt="Discover our premium aluminum doors and windows, designed for superior durability, energy efficiency, and a sleek, contemporary appearance."
                      position={{ left: '389.99px', top: '0px' }}
                    />
                    <PortfolioItem
                      category="cat-28"
                      imgSrc={image19}
                      link={image19}
                      title="Led Glass "
                      excerpt="Illuminate your space with our innovative LED glass, combining stunning design with energy-efficient lighting for a modern and elegant ambiance"
                      position={{ left: '779.98px', top: '0px' }}
                    />
                    <PortfolioItem
                      category="cat-28"
                      imgSrc={image20}
                      link={image20}
                      title="Profile LED Mirror"
                      excerpt="Elevate your bathroom with our profile LED mirror, featuring integrated lighting for a stylish and functional touch to your daily routine."
                      position={{ left: '0px', top: '524.885px' }}
                    />
                  {/* Other PortfolioItems */}
                </div>
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PortfolioItem = ({ category, imgSrc, link, title, excerpt }) => {
  return (
    <div className={`portfolio-item ${category}`}>
      <div className="portfolio-item-inner">
        <div className="portfolio-img-holder">
          <img decoding="async" src={imgSrc} alt="" />
          <div className="portfolio-overlay">
            <div className="portfolio-hover-icons">
              <a href={link} className="portfolio-link-icon">
                <span className="icon"><i aria-hidden="true" className="fas fa-link"></i></span>
              </a>
            </div>
          </div>
        </div>
        <div className="clear"></div>
        <div className="portfolio-all-content">
          <div className="portfolio-content">
            <div className="portfolio-title">
              <h4 className="portfolio-title-tag">
                <a href={link} className="portfolio-title-link">{title}</a>
              </h4>
            </div>
            <div className="portfolio-excerpt">{excerpt}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;
