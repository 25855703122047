import React, { useState, useEffect } from 'react';
import './Gallery.css';
import Header from '../Header';
import Footer from '../Footer';

const Gallery = () => {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSections = async () => {
    try {
      const response = await fetch(
        "https://satkabir-backend.onrender.com/api/sections"
      );

      if (!response.ok) {
        throw new Error("Failed to fetch sections");
      }

      const data = await response.json();
      setSections(data);
      setLoading(false);
    } catch (error) {
      setError(error.message || "Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSections();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="gallery-container">
      {/* <Header /> */}
      
      <div className="gallery-heading">
        <h2>Project Gallery</h2>
      </div>

      {sections.map((section) => (
        <div key={section.name}>
          <div className="gallery-heading">
            <h2>{section.name}</h2>
          </div>
          <div className="gallery-images">
            {section.media.map((item, index) => (
              <div key={index} className="gallery-item">
                {item.type === 'image' ? (
                  <img src={item.url} alt={item.alt || "Gallery Image"} />
                ) : (
                  <video controls style={{width: '250px',
    height: '300px'
}}>
                    <source src={item.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}

      {/* <Footer /> */}
    </div>
  );
};


export default Gallery;
