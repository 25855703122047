import React, { useEffect, useState } from 'react';
import { FaSearch, FaBars, FaAngleDown } from 'react-icons/fa';
import './Header.css';
import logoimage from '../assets/images/logoimage.jpg';
import logo from '../assets/images/logo.jpg';
import { useNavigate } from 'react-router-dom';



function MobileMenu({ isOpen, toggleMenu,  }) {
  const navigate=useNavigate()
  const handleNavigate=(data)=>{
    navigate(`/product-page/${data}`)
  }
  const [isOpens, setIsOpens] = useState(false);
  const handleClicks = () => {
    setIsOpens(!isOpens);
  };
  const [menuOpens, setMenuOpens] = useState(false);

  const toggleMenus = () => {
    setMenuOpens(!menuOpens);
  };
  return (
    <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
      <nav style={{
        position: 'relative',

        background: 'white'
      }}>
        <ul className="menu">
          <li className="menu-item"><a href="/">Home</a></li>
          <li className="menu-item"><a href="/about-us">About Us</a></li>
          <li onClick={handleClicks} className="menu-item dropdown" >
            Products <FaAngleDown style={{position: 'relative',
    top: '4px'}} />
            <ul className={`sub-menu ${isOpens ? 'show' : ''}`}>
              <li onClick={() => handleNavigate('Glass railing')}>Glass railing</li>
              <li onClick={() => handleNavigate('steel railing')}>steel railing</li>
              <li onClick={() => handleNavigate('LED mirror')}>LED mirror</li>
              <li onClick={() => handleNavigate('Profile Mirror')}>Profile Mirror</li>
              <li onClick={() => handleNavigate('Toughened Glass')}>Toughened Glass</li>
              <li onClick={() => handleNavigate('Aluminium Door')}>Aluminium Door</li>
              <li onClick={() => handleNavigate('Slim Section')}>Slim Section</li>
            </ul>
          </li>
          <li className="menu-item"><a href="/project-gallery">Project Gallery</a></li>
          {/* <li className="menu-item"><a href="/solutions">Solutions</a></li> */}
          <li className="menu-item"><a href="/contact-us">Contact Us</a></li>
        </ul>
      </nav>
    </div>
  );
}

const Header = ({ onCategoryClick }) => {
  const navigate=useNavigate()
  const [media, setMedia] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };


  
  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await fetch(
          `https://satkabir-backend.onrender.com/api/sections`

        );
         console.log(response);
        if (!response.ok) {
          throw new Error('Failed to fetch sections');
        }

        const data = await response.json();
        setSections(data);
        console.log(data);

        setLoading(false);
      } catch (error) {
        setError(error.message || 'Something went wrong');
        setLoading(false);
      }
    };

    fetchSections();
  }, []);

  const handleItemClick = (category) => {
    console.log(`Category clicked: ${category}`);
    const selectedSection = sections.find(section => section.name === category);
    console.log('Selected section:', selectedSection);
    if (!selectedSection) {
      console.error(`No section found for category: ${category}`);
    }
    setMedia(selectedSection?.media || []);
    setSelectedCategory(category);
  };

  const handleNavigate=(data)=>{
    navigate(`/product-page/${data}`)
  }
  const handleNavigates=(data)=>{
    navigate(`/project-gallery`)
  }


  return (
    <header id="masthead" className="site-header header-area header-in-container clearfix header-padding-1 has_header_icons header_style_2" style={{ background: 'rgba(28, 156, 217, 1)' }}>
      <div className="header-bottom sticky-bar header-res-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-6 col-4">
              <div className="site-branding logo">
                <a href="/" className="custom-logo-link" rel="home">
                  <img width="250" height="152" src={logoimage} className="custom-logo wp-post-image" alt="Lotus Wiindoors" />
                  {/* {windowWidth <768 &&
                 } */}
                </a>
                <h1 style={{
                  textAlign: 'center',
                  fontSize: '40px'
                }}><span style={{ fontSize: '95px' }}> SAT KABIR </span> <br />uPVC Window & Doors</h1>
                <div className="mainlogo">
                  <img src={logo} alt="" />
                </div>
              </div>
              {/* <div className="search">
                <input placeholder="Search..." type="text" />
                <button type="submit">Go</button>
              </div> */}
            </div>
            <div className="col-xl-8 col-lg-8 d-none d-lg-block">
              <div className="main-menu">
                <nav>
                  <ul id="primary-menu" className="menu">
                    <li className="menu-item current-menu-item"><a href="/">Home</a></li>
                    <li className="menu-item"><a href="/about-us">About Us</a></li>
                    <li onClick={handleClick} className="menu-item dropdown" >
                      Products <FaAngleDown style={{position: 'relative',
    top: '4px'}} />
                      <ul className={`sub-menu ${isOpen ? 'show' : ''}`}>

                        <li onClick={() => handleNavigate('Glass railing')}>Glass railing</li>
                        <li onClick={() => handleNavigate('steel railing')}>steel railing</li>
                        <li onClick={() => handleNavigate(' Led mirror')}>Led mirror</li>
                        <li onClick={() => handleNavigate(' Profile mirror')}>Profile mirror</li>
                        <li onClick={() => handleNavigate('Toughened Glass')}>Toughened Glass</li>
                        <li onClick={() => handleNavigate('Aluminium Door')}>Aluminium Door</li>
                        <li onClick={() => handleNavigate('Slim Section')}>Slim Section</li>
                      </ul>
                    </li>
                    <li className="menu-item"><a href="/project-gallery">Project Gallery</a></li>
                    {/* <li className="menu-item"><a href="/solutions">Solutions</a></li> */}
                    <li className="menu-item"><a href="/contact-us">Contact Us</a></li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 col-8">
              <div className="header-right-wrap">
                <div className="mobile-menu-toggle d-lg-none" onClick={toggleMenu}>
                  <FaBars />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileMenu isOpen={menuOpen} toggleMenu={toggleMenu} />
    </header>
  );
}

export default Header;
