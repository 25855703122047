import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';

const DashboardLayout = () => {
    const navigate=useNavigate()

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sections, setSections] = useState([]);
    const [media, setMedia] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
  
    useEffect(() => {
      const fetchSections = async () => {
        try {
          const response = await fetch(
            `https://satkabir-backend.onrender.com/api/sections`
  
          );
           console.log(response);
          if (!response.ok) {
            throw new Error('Failed to fetch sections');
          }
  
          const data = await response.json();
          setSections(data);
          console.log(data);
  
          setLoading(false);
        } catch (error) {
          setError(error.message || 'Something went wrong');
          setLoading(false);
        }
      };
  
      fetchSections();
    }, []);
  
    // if (loading) {
    //   return <p>Loading...</p>;
    // }
  
    // if (error) {
    //   return <p>Error: {error}</p>;
    // }
  
    const handleItemClick = (category) => {

      console.log(`Category clicked: ${category}`);
      const selectedSection = sections.find(section => section.name === category);
      console.log('Selected section:', selectedSection);
      if (!selectedSection) {
        console.error(`No section found for category: ${category}`);
      }
      setMedia(selectedSection?.media || []);
      setSelectedCategory(category);
      

    };
  return (
    <div className="dashboard-layout">
      <Header  onCategoryClick={handleItemClick}  />
      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default DashboardLayout;
