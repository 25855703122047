import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import ItemTable from "./ItemTable";
import AddItemForm from "./AddItemForm";
import EditItemModal from "./EditItemModel";
import itemData from "./ItemData";
import "./style.css"; // Sample data (can be fetched from API)

const Dashboard = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [items, setItems] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [media, setMedia] = useState();
  const fetchSections = async () => {
    try {
      const response = await fetch(
        "https://satkabir-backend.onrender.com/api/sections"
      );

      if (!response.ok) {
        throw new Error("Failed to fetch sections");
      }

      const data = await response.json();
if(selectedCategory){
  console.log('matching',data)

  const matchingSection = data.find(section => section.name === selectedCategory.name);
console.log('matching',matchingSection)
  if (matchingSection) {
      setMedia(matchingSection.media);
  }
}

      setSections(data);
      setLoading(false);
    } catch (error) {
      setError(error.message || "Something went wrong");
      setLoading(false);
    }
  };
  useEffect(() => {
   

    fetchSections();
  }, []);

  // if (loading) {
  //   return <p>Loading...</p>;
  // }

  // if (error) {
  //   return <p>Error: {error}</p>;
  // }
  const categories = Object.keys(itemData);

  const handleItemClick = (category) => {
    setMedia(category?.media);
    setSelectedCategory(category);
    // setItems(itemData[category]);
  };
  console.log("media", media);

  const handleAddItem = () => {
    fetchSections();

console.log('sections',sections)
    const matchingSection = sections.find(section => section.name === selectedCategory.name);

    if (matchingSection) {
        setMedia(matchingSection.media);
    }


  };

  const handleEditItem = () => {
    fetchSections();
    console.log('selectedCategory',selectedCategory)
    console.log("sections", sections);
    const matchingSection = sections.find(section => section.name === selectedCategory.name);
    if (matchingSection) {
        setMedia(matchingSection.media);
    }
    // setMedia(selectedCategory?.media);

  };

  const handleDeleteItem = () => {
    fetchSections();
console.log('sections',sections)
    const matchingSection = sections.find(section => section.name === selectedCategory.name);

    if (matchingSection) {
      console.log('matchingSection',matchingSection)
        setMedia(matchingSection.media);
    }

  };

  const openEditForm = (item) => {
    setEditItem(item);
  };

  const closeEditForm = () => {
    setEditItem(null);
  };
  const toggleAddForm = () => {
    setShowAddForm(prevState => !prevState);
  };

  return (
    <div className="app">
      <Sidebar categories={sections} onItemClick={handleItemClick} />
      <div className="main-content">
        {selectedCategory && (
          <>
            <button onClick={() => setShowAddForm(true)}>Add New Item</button>
            {showAddForm && (
              <AddItemForm
                sectionId={selectedCategory._id}
                onAdd={handleAddItem} onClose={toggleAddForm}
              />
              
            )}
            <ItemTable
              items={media}
              editItem={editItem}
              onEdit={openEditForm}
              onSave={handleEditItem}
              onDeleted={handleDeleteItem}
              onCloseEdit={closeEditForm}
              
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
