import React, { useState, useEffect } from 'react';
import './Gallery.css';
import Header from '../Header';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';

const ProductPage = (sectionId) => {
  const {product}=useParams()
  console.log('product',product)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sections, setSections] = useState([]);
  const [media, setMedia] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await fetch(
          `https://satkabir-backend.onrender.com/api/sections/name/${product}/media`

        );
         console.log(response);
        if (!response.ok) {
          throw new Error('Failed to fetch sections');
        }

        const data = await response.json();
        setMedia(data);
        console.log(data);

        setLoading(false);
      } catch (error) {
        setError(error.message || 'Something went wrong');
        setLoading(false);
      }
    };

    fetchSections();
  }, [product]);

  // if (loading) {
  //   return <p>Loading...</p>;
  // }

  // if (error) {
  //   return <p>Error: {error}</p>;
  // }

  const handleItemClick = (category) => {
    console.log(`Category clicked: ${category}`);
    const selectedSection = sections.find(section => section.name === category);
    console.log('Selected section:', selectedSection);
    if (!selectedSection) {
      console.error(`No section found for category: ${category}`);
    }
    setMedia(selectedSection?.media || []);
    setSelectedCategory(category);
  };
  return (
    <div className="gallery-container">
      <div className="header" style={{ width: '-webkit-fill-available' }}>
        {/* <Header onCategoryClick={handleItemClick} /> */}
      </div>
      <div className="gallery-heading">
        <h2>{ product || 'steel railing'}</h2>
      </div>
      <div className="gallery-images">
        {media.map((item, index) => (
          <div key={index} className="gallery-item">
            {item.type === 'image' && <img src={item.url} alt={item.alt || ''} />}
            {item.type === 'video' && <video src={item.url} controls style={{width: '250px',
    height: '300px'}} />}
          </div>
        ))}
      </div>
      <div className="footer" style={{ width: '-webkit-fill-available' }}>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default ProductPage;
